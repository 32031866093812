import React from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"

import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Secondary } from "../Section"
import { HrefButtonInverse } from "../Button"
import SvgImage from "../SvgImage"

import LogoWuji from "../../images/logo-wuji.svg"
import LogoBamBam from "../../images/logo-bambam.svg"
import { calculateRem } from "../../utils/javascript-styles"

const VideoContent = styled.div`
  position: relative;
  iframe {
    position: relative;
    z-index: 100;
  }
`
const VideoTextContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 101;
  padding-top: 10rem;
`
const VideoText = styled.h1`
  font-size: ${calculateRem(68)};
  font-weight: 700;
  text-shadow: 1px 1px 0 ${props => props.theme.black};
  text-align: center;
  color: ${props => props.theme.white};
`

const EventsEntertainment = () => {
  return (
    <section>
      <VideoContent>
        <VideoTextContainer>
          <VideoText>Events & Entertainment</VideoText>
        </VideoTextContainer>
        <ReactPlayer
          url="https://youtu.be/axn3iDSyHMs"
          playing
          width={1680}
          height={720}
        />
      </VideoContent>

      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoWuji}
              altText="The Joyner Hand Logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>DJ BAMM BAMM</h3>
            <p>
              Wuji, a philosophy meaning “limitless” and “infinite” is the state
              of entertainment that we believe in. Your soulful inner spirit
              should become a part of our every day life. Peace and love
              prevails. Wuji entertainment focus’ on event photography with Bam
              Bam Photography and lounge music with DJ Bamm Bamm.
            </p>
            <HrefButtonInverse href="http://joynermd.com/contact">
              Contact DJ Bamm Bamm
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
      <Secondary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoBamBam}
              altText="Touchless Chopsticks logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>BAM BAM PHOTOGRAPHY</h3>
            <p>
              Bam Bam photography is a subdivision of Wuji Entertainment.
              ”History in the making” is the theme of Bam Bam Photography. Your
              event is historic; therefore, document with the art of
              photography. Visit the website to see your event on the Bam Bam
              Photography page.
            </p>
            <HrefButtonInverse href="http://joynermd.com/contact">
              Contact Bam Bam Photography
            </HrefButtonInverse>
          </Col>
        </Row>
      </Secondary>
    </section>
  )
}

export default EventsEntertainment
