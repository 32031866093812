import React from "react"
import EventsEntertainment from "../components/EventsEntertainment"
import { Layout } from "../components/Layout"

const EventsEntertainmentPage = () => {
  return (
    <Layout>
      <EventsEntertainment />
    </Layout>
  )
}

export default EventsEntertainmentPage
